import storage from '@/Utils/storage';
import { Home, People, Logout } from '@mui/icons-material';
import { NavigationLink } from './NavigationLink';

export const TopNav = () => {
  const user = storage.user.get();

  return (
    <div
      className='topNavigation'
      style={{
        display: 'inline-flex',
        paddingLeft: '24px',
        paddingRight: '24px',
        width: '100%',
      }}
    >
      <NavigationLink to='/' text='Dashboard' icon={<Home />} />
      <NavigationLink to='/clients' text='Clients' icon={<People />} />
      <NavigationLink
        to='/signout'
        text={`Sign Out | ${user.username}`}
        icon={<Logout />}
        style={{ marginLeft: 'auto', marginRight: '1rem' }}
      />
    </div>
  );
};
