import { createSlice } from '@reduxjs/toolkit';
import { User } from '@kip/common';
import { RootState } from '@/Store/store';
import storage from '@/Utils/storage';

export type AuthSliceState = {
  accessToken?: string;
  user?: User;
};

const initialState: AuthSliceState = {
  accessToken: undefined,
  user: undefined,
};

export const {
  actions: {
    setAccessToken,
    setAuth,
    setUser,
    clearAccessToken,
    clearAuth,
    clearUser,
  },
  reducer: authReducer,
} = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAccessToken: (state, { payload }) => {
      const { accessToken } = payload;
      storage.token.set(accessToken);
      state.accessToken = accessToken;
    },
    setAuth: (state, { payload }) => {
      const { accessToken, user } = payload;
      storage.token.set(accessToken);
      storage.user.set(user);
      state.accessToken = accessToken;
      state.user = user;
    },
    setUser: (state, { payload }) => {
      const { user } = payload;
      storage.user.set(user);
      state.user = user;
    },
    clearAccessToken: (state) => {
      storage.token.clear();
      state.accessToken = undefined;
    },
    clearAuth: (state) => {
      storage.token.clear();
      storage.user.clear();
      state.accessToken = undefined;
      state.user = undefined;
    },
    clearUser: (state) => {
      storage.user.clear();
      state.user = undefined;
    },
  },
});

export const AUTH_ACCESS_TOKEN_SELECTOR = (state: RootState) =>
  storage.token.get();
export const AUTH_USER_SELECTOR = (state: RootState) => storage.user.get();
