import { ErrorBoundary } from 'react-error-boundary';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { ErrorFallback } from '@/ErrorFallback';
import { NotificationProvider } from '@/Components/Notifications/NotificationProvider';
// import { SignIn } from '@/Features/Auth';
// import { Dashboard } from '@/Features/Dashboard/Dashboard';
// import { ClientView, ClientsListView } from '@/Features/Clients';
// import { SignOut } from './Features/Auth/SignOut';
import Layout from './Components/Layout';
import { ThemeProvider } from '@mui/material';
import { kipTheme } from '@/Theme';
import storage from './Utils/storage';

const ClientsListView = React.lazy(
  () => import('@/Features/Clients/ClientsListView')
);
const ClientView = React.lazy(() => import('@/Features/Clients/ClientView'));
const Dashboard = React.lazy(() => import('@/Features/Dashboard/Dashboard'));
const SignIn = React.lazy(() => import('@/Features/Auth/SignIn'));
const SignOut = React.lazy(() => import('@/Features/Auth/SignOut'));

const App = () => {
  const [accessToken, setAccessToken] = useState(storage.token.get());
  const [isSignedIn, setIsSignedIn] = useState(!!accessToken);

  const renderLoader = () => <div>Loading.</div>;

  useEffect(() => {
    setIsSignedIn(!!accessToken);
  }, [accessToken]);

  return (
    <BrowserRouter>
      <ThemeProvider theme={kipTheme}>
        <Layout isSignedIn={isSignedIn}>
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <React.Suspense fallback={renderLoader()}>
              <NotificationProvider />
              <Routes>
                {!!accessToken ? (
                  <>
                    <Route path='/' element={<Dashboard />} />
                    <Route path='clients' element={<ClientsListView />} />
                    <Route path='clients/:clientId' element={<ClientView />} />
                    <Route
                      path='signout'
                      element={
                        <SignOut
                          success={() => setAccessToken(storage.token.get())}
                        />
                      }
                    />
                    <Route path='*' element={<Navigate replace to='/' />} />
                  </>
                ) : (
                  <>
                    <Route
                      path='/'
                      element={<Navigate replace to='/signin' />}
                    />
                    <Route
                      path='/signin'
                      element={
                        <SignIn
                          success={(accessToken: string) =>
                            setAccessToken(accessToken)
                          }
                        />
                      }
                    />
                  </>
                )}
                <Route path='*' element={<Navigate replace to='/' />} />
              </Routes>
            </React.Suspense>
          </ErrorBoundary>
        </Layout>
      </ThemeProvider>
    </BrowserRouter>
  );
};

export default App;
